<template>
  <div id="user" class="grid">
    <div class="pg-header">
      <div class="pg-back-button">
        <button class="btn-primary" @click="back">Tillbaka</button>
      </div>
      <h2 v-if="userId < 1">Ny användare</h2>
      <h2 v-if="userId > 0">Editera användare</h2>
    </div>
    <div v-if="myUser" class="pg-form">
      <div class="pg-form-group">
        <label class="pg-label">Förnamn</label>
        <input ref="firstname" v-model="myUser.first_name" class="pg-input" type="text" />
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Efternamn</label>
        <input v-model="myUser.last_name" class="pg-input" type="text" />
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Roll</label>
        <select v-model="myUser.role_id" class="pg-input">
          <option v-for="role in myRoles" :key="role.role_id" :value="role.role_id" :selected="role.role_id === myUser.role_id">
            {{ role.role_description }}
          </option>
        </select>
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Användarnamn</label>
        <input v-model="myUser.username" class="pg-input" type="text" />
        {{ error }}
      </div>
      <div v-if="userId < 1">
        <div class="pg-form-group">
          <label class="pg-label">Lösenord</label>
          <input v-model="myUser.password" class="pg-input" type="password" />
        </div>
      </div>
      <br />
      <div class="pg-form-group">
        <button class="btn-primary" @click="saveMyUser">Spara</button>
      </div>
      <div v-if="userId > 0">
        <div class="pg-form-group">
          <button class="btn-danger" @click="deleteMyUser">Radera</button>
        </div>
        <br />
        <hr />
        <div class="pg-header">
          <h4>Ändra lösenord</h4>
        </div>
        <div class="pg-form-group">
          <label class="pg-label">Nytt lösenord</label>
          <input v-model="password" class="pg-input" type="password" />
        </div>
        <div class="pg-form-group">
          <label class="pg-label">Bekräfta lösenord</label>
          <input v-model="password2" class="pg-input" type="password" />
          <small v-if="showError">Passwords don't match!</small>
        </div>
        <div class="pg-form-group">
          <button class="btn-primary" @click="saveMyUserPassword">Ändra lösenord</button>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'User',
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      search: '',
      showError: false,
      error: '',
      password: '',
      password2: '',
      myRoles: {},
      myUser: {
        user_id: 0,
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        role_id: 1
      }
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    isSeller: function () {
      return this.$store.getters.isSeller;
    }
  },
  created() {},
  async mounted() {
    await this.fetchMyRoles();

    if (this.userId > 0) {
      await this.fetchMyUser();
    } else {
      this.$refs.firstname.focus();
    }
  },
  methods: {
    ...mapActions({
      fetchUser: 'users/fetchUser',
      addUser: 'users/addUser',
      updateUser: 'users/updateUser',
      updateUserPassword: 'users/updateUserPassword',
      deleteUser: 'users/deleteUser',
      fetchRoles: 'misc/fetchRoles'
    }),
    async deleteMyUser() {
      const result = confirm(`Är du säker att du vill radera ${this.myUser.full_name}?`);
      if (result) {
        let response = null;
        try {
          let data = {
            user_id: this.userId
          };
          response = await this.deleteUser(data);
        } catch (error) {
          // console.log(error);
          // TODO : FIX ERROR LOG
        } finally {
          let addUpdateOK = false;
          if (response.success == 1) {
            addUpdateOK = true;
          }
          this.$router.replace({
            name: 'Users',
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'DELETE'
            }
          });
          // console.log("We do cleanup here");
        }
      }
    },
    back: function () {
      this.$router.replace({
        name: 'Users',
        params: {
          addUpdateOK: false,
          hasAddUpdate: false,
          action: ''
        }
      });
    },
    async fetchMyRoles() {
      try {
        this.myRoles = [];

        this.myRoles = await this.fetchRoles();
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //    console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async fetchMyUser() {
      try {
        this.myUser = [];
        let data = {
          user_id: this.userId
        };
        this.myUser = await this.fetchUser(data);
      } catch (error) {
        // console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async saveMyUser() {
      let response = null;

      try {
        let data = {
          user_id: this.myUser.user_id,
          first_name: this.myUser.first_name,
          last_name: this.myUser.last_name,
          username: this.myUser.username,
          password: this.myUser.password,
          role_id: this.myUser.role_id
        };

        if (this.userId > 0) {
          response = await this.updateUser(data);
        } else {
          response = await this.addUser(data);
        }
      } catch (error) {
        // console.log("saveMyUser ERROR");
        // console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //  console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
          if (error.response.data) {
            if (error.response.data.status === 1003) {
              this.error = 'Användarnamnet finns redan';
            }
          }
        }
      } finally {
        let addUpdateOK = false;

        if (response && response.success == 1) {
          addUpdateOK = true;

          this.$router.replace({
            name: 'Users',
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'SAVE'
            }
          });
        }
      }
    },
    async saveMyUserPassword() {
      if (this.password !== this.password2) {
        this.showError = true;
        return;
      }

      let response = null;

      try {
        let data = {
          user_id: this.myUser.user_id,
          password: this.password
        };

        response = await this.updateUserPassword(data);
      } catch (error) {
        //  console.log("saveMyUserPassword ERROR");
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //  console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        let addUpdateOK = false;
        if (response.success == 1) {
          addUpdateOK = true;
        }
        this.$router.replace({
          name: 'Users',
          params: {
            addUpdateOK: addUpdateOK,
            hasAddUpdate: true,
            action: 'SAVE'
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
